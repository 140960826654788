import React from 'react';
import { imgStar } from './assets';
import CurrencyList from "currency-list";
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';
import { defaultLang } from '../../blocks/utilities/src/translations/consts';

const arLanguage = require('../../blocks/utilities/src/translations/ar.json');
const enLanguage = require('../../blocks/utilities/src/translations/en.json');

export enum Languages {
    en = 'en',
    ar = 'ar'
};

export function getStars(avgReview: number | null) {
    return avgReview ? Array.from(Array(Math.round(avgReview))).map((item, index) =>
        <img key={index} src={imgStar} alt='image star' />
    ) : ''
}

export const isPlural = (amount: number, word: string, ending = "s") =>
    amount === 1 ? word : word + ending;

export const findCurrByCode = (currCode: string) => Object.values(CurrencyList.getAll("en_US")).find(
    (curr: Record<string, string>) => {
        return curr.code === currCode
    }
)?.symbol || '$';

export const getAndSaveCurrency = (responseJson: any) => {
    const currCode = responseJson?.data?.attributes.currency;
    const currancy = findCurrByCode(currCode);
    localStorage.setItem('currency', currancy);

    return currancy;
}

export const createFakeApiMessage = (instanceVar: string, response: Record<string, any>) => {
    const getUserDataEmpImages = new Message(
        getName(MessageEnum.RestAPIResponceMessage)
    );

    getUserDataEmpImages.addData(
        getName(MessageEnum.RestAPIResponceDataMessage),
        getUserDataEmpImages.messageId
    )

    getUserDataEmpImages.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        response
    );
    instanceVar = getUserDataEmpImages.messageId;
    runEngine.sendMessage("Unit test", getUserDataEmpImages);
}

export const getLocalTranslations = (lang: string = defaultLang): { [k: string]: string } => {
    switch (lang) {
        case 'ar':
            return arLanguage;
        default:
            return enLanguage;
    }
};

export const getTranslationConfig = () => {
    const { t } = window as unknown as { t: Record<string, string> };
    const lang = localStorage.getItem('language');
    
    const isArabic = lang === (Languages.ar as string);
    const dir = isArabic ? 'rtl' : 'ltr';

    return { t, dir, isArabic }
}
