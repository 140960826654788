import React from "react";

// Customizable Area Start
import { Box, Typography, Tabs, Tab, CircularProgress, LinearProgress } from "@material-ui/core";
import { Chart } from "react-google-charts";
import './charts.css'
// Customizable Area End

import VisualAnalyticsController, {
  Props,
} from "./VisualAnalyticsController";
import { styles, TypographyView } from '../../../components/src/styles';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { KeyboardArrowRight, KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { CustomTabPanel } from './components/CustomTabPanel';

const statisticsTypes = [
  'Sales Analysis',
  'Clients Analytics'
];

const customStyle = {
  arrows: {
    color: '#000000',
    height: '15px',
    position: 'relative'
  },
  statRowFirstHead: {
    fontSize: '12px',
    fontWeight: 700,
    width: '70%',
    textAlign: 'left',
  },
  statRowSecondVal: {
    fontSize: '12px',
    width: '30%',
    textAlign: 'center',
    fontWeight: 700,
  },
  statRowName: {
    fontSize: '12px',
    width: '70%',
    textAlign: 'left',
  },
  statRowVal: {
    fontSize: '12px',
    width: '30%',
    textAlign: 'center',
  },
  legend: {
    fontSize: '16px',
    marginLeft: '8px',
    color: '#000000'
  }
}

export default class VisualFullAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigationMap = {
    'Home': 'LandingPage',
    'Spa Profile': 'SpaUserProfile',
  }

  getCustomTab = (index: number, value: number | undefined, text: string) =>
    <Tab
      {...this.getA11yProps(index)}
      label={
        <Box
          display='flex'
          justifyContent='space-between'
          width='100%'
        >
          <Box>
            <Typography
              style={{
                fontSize: '30px',
                fontWeight: 700,
                color: '#000000',
                textAlign: 'left',
                minHeight: '45px'
              }}
            >
              {value}
            </Typography>
            <Typography
              style={{
                color: '#0F172A',
                textTransform: 'none'
              }}>
              {text}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            <KeyboardArrowDown
              style={{
                top: '3px',
                ...customStyle.arrows
              } as React.CSSProperties} />
            <KeyboardArrowUp
              style={{
                top: '-3px',
                ...customStyle.arrows
              } as React.CSSProperties} />
          </Box>
        </Box>}
    />

  // Customizable Area End

  render() {
    // Customizable Area Start
    const activeTab = this.state.activeTabIndex;
    const showFirstStatBlock = this.state.activeAnalysisTypeIndex === 0;
    const showSecondStatBlock = this.state.activeAnalysisTypeIndex === 1;

    const {
      total_bookings,
      month_sales_data,
      online_offline_bookings_per_year,
      get_bookings_by_geolocation,
      booking_by_categories: services,
      bookings_by_gender
    } = this.state.fullReport || {};

    const servicesCount = services?.length;

    const maxBookingValue = Number(services?.reduce(
      (
        acc: number,
        next: { booked_slots_count: number }
      ) =>
        Math.max(acc, next.booked_slots_count), 0
    ));

    const { labels: initLabels, data: initYAxisData } = month_sales_data?.data || { };

    // Apply only 12 months to statistics for a full year
    const labels = initLabels?.slice(0, initLabels.length - 2).reverse();
    const yAxisData = initYAxisData?.slice(0, initYAxisData.length - 1).reverse();

    const {
      online,
      offline
    } = online_offline_bookings_per_year || {};

    const {
      total_bookings_inside_city,
      total_bookings_outside_city
    } = get_bookings_by_geolocation || {};

    const {
      female,
      male
    } = bookings_by_gender || {};

    const genderStat = [
      {
        name: 'Female',
        value: female
      },
      {
        name: 'Male',
        value: male
      }
    ]

    const bookingSalesData: (
      string | number | undefined |
      Record<string, string | Record<string, boolean>> |
      React.ReactNode
    )[][] = [[
      '',
      'No. of Booking',
      { type: 'string', role: 'style' },
      { type: 'string', role: 'tooltip', p: { 'html': true } }
    ]];

    const today = new Date();
    const curYear = today.getFullYear();
    const pastYear = curYear - 1;
    const curMonth = today.getMonth();

    labels?.forEach((label: string, index: number) => {
      const targetYear = index < labels.length - curMonth ? pastYear : curYear;
      const dateString = label + targetYear;
      const monthName = new Date(dateString).toLocaleString('default', { month: 'long' });
      const resDateString = `${monthName} ${targetYear}`;

      const axisValue = yAxisData?.[index][0];
      const chartItem = [
        label,
        axisValue,
        'point { fill-color: #F59E0B; }',
        `<div>
          <div style="padding: 8px; background-color: #F1F5F9; font-size: 12px">
            ${resDateString}
          </div>
          <div style="padding: 8px; background-color: #D9EEEB; font-size: 12px">
            No. of Booking<span style="font-weight: 700; margin-left: 12px">${axisValue}</span>
          </div>
        </div>`
      ];
      bookingSalesData.push(chartItem);
    })

    const bokingSalesOptions = {
      curveType: "function",
      legend: { position: 'none' },
      colors: ['#398378'],
      vAxis: { format: 'short' },
      tooltip: {
        isHtml: true,
        trigger: 'selection'
      }
    };

    const servicesStatData = [
      ["Service Type", "Type Percentage"],
      ["Online Booking", online],
      ["Offline Booking", offline]
    ];

    const locationStatData = [
      ["No. of customers", "No. Percentage"],
      ["No. of customers by country", total_bookings_outside_city],
      ["No. of customers by city", total_bookings_inside_city]
    ];

    const donutServicesOptions = {
      pieHole: 0.75,
      legend: {
        position: 'labeled',
        color: '#334155'
      },
      pieSliceText: 'none',
      backgroundColor: '#E8F6F4',
      chartArea: {
        left: 0,
        height: 250,
        width: 400
      },
      colors: ['#398378', '#F59E0B']
    };

    const donutLocationOptions = {
      ...donutServicesOptions,
      backgroundColor: '#FFFFFF',
      legend: {
        position: 'none',
      },
    }

    return (
      <TypographyView>
        {this.state.isLoading &&
          <CircularProgress
            color='primary'
            size={64}
            style={styles.absoluteCentered as React.CSSProperties}
          />}
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader />
          <Box className='search-location-block' py={6} px='140px'>
            <BreadCrumbs
              items={['Home', 'Spa Profile', 'Ad-hoc reporting']}
              targetColor='#398378'
              navigationMap={this.navigationMap}
              navigate={this.props.navigation.navigate}
            />
            <Typography
              style={{
                color: '#0F172A',
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: 700,
                padding: '30px 0'
              }}
            >Ad-hoc reporting</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>
            {/* Charts Block */}
            <Box mt={4} display='flex' gridGap={16}>
              {statisticsTypes.map((statType, index) =>
                <Box
                  data-test-id='statistics-tabs'
                  border='1px solid #7EC4B9'
                  borderRadius={16}
                  p={2}
                  minWidth='275px'
                  key={statType}
                  style={{ cursor: 'pointer', ...this.setActiveAnalysisTypeStyle(index) }}
                  onClick={() => this.setActiveAnalysisTypeIndex(index)}
                >
                  <Box mt='10%'>
                    <Typography
                      style={{
                        fontSize: '18px',
                        fontWeight: 700
                      }}
                    >
                      {statType}
                    </Typography>
                    <Box
                      mt={1}
                      p={1}
                      bgcolor='#FFFFFF'
                      display='flex'
                      alignItems='center'
                      borderRadius={8}
                      gridGap={10}
                      width='fit-content'
                    >
                      <Typography>
                        Average/ <span style={{ fontWeight: 700 }}>Month</span>
                      </Typography>
                      <KeyboardArrowRight
                        style={{
                          color: '#000000',
                          fontWeight: 700
                        }}
                      />
                    </Box>
                  </Box>
                </Box>)}
            </Box>
            {showFirstStatBlock &&
              <>
                <Typography
                  style={{
                    margin: '32px 0',
                    color: '#000000',
                    fontSize: '30px',
                    fontWeight: 700
                  }}>
                  Sales Analysis <span style={{ color: '#334155', fontSize: '18px' }}>{'( last 1 year )'}</span>
                </Typography>
                {/* Tabs */}
                <Box>
                  <Box>
                    <Tabs
                      value={activeTab}
                      variant='fullWidth'
                      onChange={
                        (event: React.ChangeEvent<{}>, newValue: any) =>
                          this.setActiveTabIndex(newValue)}
                      aria-label="basic tabs example"
                    >
                      {this.getCustomTab(0, total_bookings, 'Total Number of Bookings')}
                      {this.getCustomTab(1, servicesCount, 'Total Services Booked')}
                    </Tabs>
                  </Box>
                </Box>
                <Box
                  mt={3}
                  width='100%'
                  position='relative'
                  minHeight='375px'
                >
                  {/* Linear chart */}
                  <CustomTabPanel value={activeTab} index={0}>
                    {this.state.fullReport ?
                      <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="375px"
                        data={bookingSalesData}
                        options={bokingSalesOptions}
                      /> :
                      <CircularProgress
                        style={{
                          color: '#398378',
                          ...styles.absoluteCentered
                        } as React.CSSProperties}
                      />}
                  </CustomTabPanel>
                  {/* Donut chart */}
                  <CustomTabPanel value={activeTab} index={1}>
                    <Box
                      display='flex'
                      flexWrap='nowrap'
                      width='100%'
                    >
                      <Box width="50%" position='relative'>
                        <Typography
                          style={{
                            ...styles.absoluteCentered,
                            fontSize: '18px',
                            fontWeight: 700,
                            zIndex: 1,
                            left: '200px'
                          } as React.CSSProperties}>
                          {`${servicesCount} Services`}
                        </Typography>
                        <Chart
                          chartType="PieChart"
                          data={servicesStatData}
                          options={donutServicesOptions}
                          height='300px'
                        />
                        {/* Static legend */}
                        <Box position='absolute' right={50} bottom={20}>
                          <Box display='flex' alignItems='center'>
                            <Box
                              bgcolor='#398378'
                              height={12}
                              width={12}
                              borderRadius={50}
                            />
                            <span style={customStyle.legend}>
                              Online Booking
                            </span>
                          </Box>
                          <Box display='flex' alignItems='center'>
                            <Box
                              bgcolor='#F59E0B'
                              height={12}
                              width={12}
                              borderRadius={50}
                            />
                            <span style={customStyle.legend}>
                              Offline Booking
                            </span>
                          </Box>
                        </Box>
                      </Box>
                      <Box width='50%' {...styles.centered}>
                        <Box width='65%'>
                          <Typography style={{
                            fontSize: '20px',
                            fontWeight: 700,
                            marginBottom: 16
                          }}>
                            Services Type Booked
                          </Typography>
                          <Box display='flex' flexWrap='nowrap' py={1}>
                            <Box style={{ fontWeight: 700, ...customStyle.statRowFirstHead as React.CSSProperties }}>Category</Box>
                            <Box style={{ fontWeight: 700, ...customStyle.statRowSecondVal as React.CSSProperties }}>Booking</Box>
                          </Box>
                          {services?.map((booking) =>
                            <Box
                              display='flex'
                              flexWrap='nowrap'
                              py={1}
                              key={booking.id}
                            >
                              <Box style={customStyle.statRowName as React.CSSProperties}>
                                <Box>{booking.name}</Box>
                                <LinearProgress
                                  variant='determinate'
                                  color='primary'
                                  value={booking.booked_slots_count / maxBookingValue * 100}
                                />
                              </Box>
                              <Box style={customStyle.statRowVal as React.CSSProperties}>{booking.booked_slots_count}</Box>
                            </Box>)}
                        </Box>
                      </Box>
                    </Box>
                  </CustomTabPanel>
                </Box>
              </>}
            {showSecondStatBlock &&
              <>
                <Typography
                  style={{
                    margin: '32px 0',
                    color: '#000000',
                    fontSize: '30px',
                    fontWeight: 700
                  }}>
                  User Engagement
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '18px'
                    }}
                  >
                    {' ( last 1 year )'}
                  </span>
                </Typography>
                <Box
                  mt={3}
                  width='100%'
                  position='relative'
                  minHeight='375px'
                >
                  {/* Donut chart */}
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    flexWrap='nowrap'
                    width='100%'
                    gridGap={16}
                  >
                    <Box
                      width="55%"
                      position='relative'
                    >
                      <Typography
                        style={{
                          ...styles.absoluteCentered,
                          top: '50%',
                          left: '200px',
                          fontSize: '18px',
                          fontWeight: 700,
                          zIndex: 1,
                          color: '#000000'
                        } as React.CSSProperties}>
                        Total Customers
                        <span
                          style={{
                            marginTop: '8px',
                            display: 'block',
                            fontWeight: 700,
                            textAlign: 'center'
                          }}
                        >
                          {total_bookings}
                        </span>
                      </Typography>
                      <Chart
                        chartType="PieChart"
                        data={locationStatData}
                        options={donutLocationOptions}
                        height='300px'
                      />
                      {/* Static legend */}
                      <Box position='absolute' right={50} bottom={20}>
                        <Box display='flex' alignItems='center'>
                          <Box
                            bgcolor='#398378'
                            height={12}
                            minWidth={12}
                            borderRadius={50}
                          />
                          <span style={customStyle.legend}>
                            No. of customers by countries
                          </span>
                        </Box>
                        <Box display='flex' alignItems='center'>
                          <Box
                            bgcolor='#F59E0B'
                            height={12}
                            minWidth={12}
                            borderRadius={50}
                          />
                          <span style={customStyle.legend}>
                            No. of customers by city
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      width='45%'
                      bgcolor='#FFFFFF'
                      {...styles.centered}
                    >
                      <Box width='65%'>
                        <Typography style={{
                          fontSize: '20px',
                          fontWeight: 700,
                          marginBottom: 16
                        }}>
                          No. of Customers by gender
                        </Typography>
                        <Box display='flex' flexWrap='nowrap' py={1}>
                          <Box style={{ fontWeight: 700, ...customStyle.statRowFirstHead as React.CSSProperties }}>Category</Box>
                          <Box style={{ fontWeight: 700, ...customStyle.statRowSecondVal as React.CSSProperties }}>Views</Box>
                        </Box>
                        {genderStat.map((booking) =>
                          <Box
                            display='flex'
                            flexWrap='nowrap'
                            py={1}
                            key={booking.name}
                          >
                            <Box style={customStyle.statRowName as React.CSSProperties}>
                              <Box>{booking.name}</Box>
                              <LinearProgress
                                variant='determinate'
                                color='primary'
                                value={Number(booking.value) / maxBookingValue * 100}
                              />
                            </Box>
                            <Box style={customStyle.statRowVal as React.CSSProperties}>{booking.value}</Box>
                          </Box>)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>}
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
